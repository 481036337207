<script>
import { postApi} from "../assets/js/helper.js";
export default {
    data() {
        return {
            packages_id: '',
        }
    },
    props: {
        plan: {
            type: Boolean,
            required: true,
        }
    },
    created() {
        this.top_header_data();
    },
    methods: {
        hide() {
            this.$emit('hide-modal');
        }, async top_header_data() {
            const response = await postApi(
                "/top_header_data",
                {
                    ca_id: localStorage.getItem("admin_id"),
                },
            );
            try {
                if (response.errorCode == "0") {
                    this.packages_id = response.data.packages_id;
                    // this.packages_id = 1;
                    return false;
                }
            } catch (err) {
                alert(err);
            }
        },
    }
}
</script>

<template>
    <!-- Use v-show to control visibility without destroying the component -->
    <div v-show="plan" class="wrapp">
        <div class="noti">
            <p>You are currently subscribed to <span v-if="packages_id == 1">Basic</span> <span
                    v-if="packages_id == 2">Business</span> <span v-if="packages_id == 3">Enterprise</span> Version. Upgrade to
                experience this feature.</p>
            <button class="tna on_e">Upgrade Now</button>
            <button @click="hide" class="tna tw_o">No Thanks</button>
        </div>
    </div>
</template>
<style>
.wrapp {
    background: #3333336b;
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 9999;
}

.noti {
    position: fixed;
    width: 100%;
    bottom: 0%;
    padding: 2%;
    background: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noti p {
    margin: 0px;
    font-size: 19px;
}

button.tna {
    background: linear-gradient(to left top, rgb(255, 174, 0) 0%, rgb(246, 106, 89) 100%) !important;
    border-color: rgb(254, 164, 13);
    border-radius: 5px !important;
    margin-right: 1%;
    color: rgb(255, 255, 255);
    float: left;
    padding: 8px;
    border: 0px;
    margin-left: 14px;
}

button.tna.tw_o {
    background: #333 !important;
}
</style>