<template>
    <CommonHeader />
    <div id="main-content">
        <div id="page-container">
            <h1 class="hed" style="">Documents </h1>
            <button class="back_user btn" @click="goBack" style="padding: 5px 1%;margin-right: 0%;"><i
                    class="fa fa-angle-double-left" aria-hidden="true"></i> Back</button>
            <div class="clearfix"></div>
            <datepicker v-model="date" inputFormat="dd-MM-yyyy" id="start_date" class="cal1" style="padding: 4%;">
            </datepicker>
            <span style="float: left;width: 34px;margin-top: 1%;">To</span>
            <datepicker v-model="date2" inputFormat="dd-MM-yyyy" id="end_date" class="cal1" style="padding: 4%;">
            </datepicker>
            <button class="btn submit" @click="get_user_documents()" type="submit" value="Custom Month"
                style="    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;border-color: #fea40d;border-radius: 5px !important;margin-right: 1%;color: #fff;float: left;padding: 8px;">Submit</button>
            <button
                style="     background: linear-gradient(to left top, rgb(255, 174, 0) 0%, rgb(246, 106, 89) 100%) !important;border-color: rgb(254, 164, 13);border-radius: 5px !important;padding: 8px 13px;color: rgb(255, 255, 255);margin: 0px;border: 0px;cursor:pointer;margin-top: 1px;float: left;"
                @click="showModelUpload">Upload Invoice             </button>
            <div class="search search_earning" style="">
                <input id="myInput" type="text" placeholder="Search.." @keyup="performSearch(this)" v-model="search"
                    style=""> <button style="padding: 0;border: none;border-radius:10px;float: right;"><i
                        class="fa fa-search" aria-hidden="true"></i></button>
            </div>
            <table class="list-box">
                <tr class="list">
                    <th>File Name</th>
                    <th>Upload Date</th>
                    <th>Status</th>
                    <th>Download</th>
                </tr>
                <tr v-for="(document, index) in userDocuments" :key="index">
                    <td>{{ document.doc_file_name }}</td>
                    <td>{{ document.created_at }}</td>
                    <td>
                        <button class="sync" v-if="document.status == 1">Synced</button>
                        <button class="unsync" v-else>In-process</button>
                    </td>
                    <td>
                        <a :href="document.doc_file">
                            <button type="button" class="btn btn-success btn-sm orange_Color"
                                style="margin-right: 5%;padding: 0px 9px !important;height: 30px;border-radius: 5px !important;">
                                Download
                            </button>
                        </a>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <!-- sidebar -->
    <div class="upload_doc" v-if="uploaddoc_sidebar">
        <h1>Upload Document</h1>
        <span role="button" aria-hidden="" class="closed_upload_doc" @click="closeModelUpload">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
        <form v-on:submit.prevent="handleSubmit">
            <div class="in_p">
                <label>Document type</label>
                <select v-model="type">
                    <option value="0">Purchase</option>
                    <option value="1">Expense</option>
                    <option value="2">Bank</option>
                    <option value="3">Other</option>
                </select>
            </div>
            <div class="in_p">
                <label>Upload Document</label>
                <div class="panel panel-default" style="">
                    <div id="_2_1" class="uploader_purchase">
                        <input id="fileUpload" type="file" name="fileUpload2" ref="file" required=""
                            v-on:change="onFileChange2">
                        <label for="fileUpload" id="file-drag_1">
                            <img id="file-image_1" src="#" alt="" class="hidden1">
                            <div id="start2">
                                <i class="fa fa-download" aria-hidden="true" style="font-size:40px;"></i>
                                <div></div>
                                <div id="notimage_2" class="hidden2" style="color :red">Please select an CSV file format
                                </div>
                                <span id="file-upload-btn" class="btn btn-primary">Browse</span>
                            </div>
                            <div id="response2" class="hidden2" v-if="upload">
                                <div id="messages2">{{ import_file_name }}</div>
                                <div class="progress">
                                    <div class="value" :style="{ width: `${value}%` }"></div>
                                </div>
                            </div>
                        </label>
                        <div class="form-group" style="display: none;">
                            <input type="checkbox" name="header" checked=""> File contains header row?
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
                <div class="gst-user" style="margin-bottom:10px;">
                    <button type="submit" class="btn btn-success account_submit">
                        <img src="https://instabill.co/public/front/images/loader.gif" class="load_show" v-if="loader7"
                            style="width: 15px;" />
                        UPLOAD
                    </button>
                </div>
            </div>
        </form>

    </div>

</template>

<script>
import CommonHeader from './CommonHeader.vue';
import datepicker from 'vue3-datepicker';
import { postApi } from "../assets/js/helper.js";
import axios from 'axios';
import $ from 'jquery';
export default {
    name: 'Doucments',
    components: {
        datepicker,
        CommonHeader
    },
    props: ["user_id", "company_id"],
    data() {
        return {
            date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date2: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            userDocuments: null,
            search: "",
            upload: false,
            uploaddoc_sidebar: false,
            type: "",
            uploadedDocument: null,
        }
    },
    created() {
        this.getuserDocuments(this.user_id, this.company_id);
    },
    methods: {
        async getuserDocuments(userId, companyId) {
            const response = await postApi(
                "/uploaded_documents",
                {
                    userId: userId,
                    company_id: companyId,
                    start_date: this.date,
                    end_date: this.date2,
                },
                {}
            );
            try {
                if (response.errorCode == "0") {
                    this.userDocuments = response.data;
                }
                return false;
            } catch (err) {
                alert(err);
            }
        },
        async get_user_documents() {
            const response = await postApi(
                "/uploaded_documents",
                {
                    userId: this.user_id,
                    company_id: this.company_id,
                    start_date: this.date,
                    end_date: this.date2,
                },
                {}
            );
            try {
                if (response.errorCode == "0") {
                    this.userDocuments = response.data;
                }
                return false;
            } catch (err) {
                alert(err);
            }

        },
        showModelUpload() {
            this.uploaddoc_sidebar = true;
        },
        closeModelUpload() {
            this.uploaddoc_sidebar = false;
        },
        performSearch() {
            var value = (this.search).toLowerCase();

            $("table tr").each(function (index) {
                if (index !== 0) {
                    var id = $(this).children().text().toLowerCase()
                    if (id.indexOf(value) < 0) {
                        $(this).hide();
                    } else {
                        $(this).show();
                    }
                }
            });
        },
        onFileChange2(event) {
            if (this.file !== null) {
                this.upload = true;
                var file = document.getElementById("fileUpload").files[0].name;
                this.import_file_name = file;
                this.value += 100;
            }
            this.uploadedDocument = event.target.files[0];
        },
        goBack() {
            this.$router.go(-1)
        },
        handleSubmit() {
            let formData = new FormData();
            formData.append('userId', this.user_id);
            formData.append('company_id', this.company_id);
            formData.append('type', this.type);
            if (this.uploadedDocument) {
                formData.append('doc', this.uploadedDocument);
            }
            axios.post("https://instabill.co/api/upload_documents", formData, {
                headers: {
                },
            }).then((response) => {
                console.log(response);
                if (response.data.errorCode == "1") {
                    this.alertMessage = response.data.errorMsg;
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
                } else {
                    this.uploaddoc_sidebar = false;
                    this.alertMessageg = 'Document Update Successfully';
                    this.alertClassg = "error_msgg";
                    this.messageg = true;
                    setTimeout(() => this.messageg = false, 3000);
                }
            }).catch((error) => {
                alert(error);
            });
        },
    }
}
</script>

<style scoped>
.upload_doc {}

.upload_doc {
    float: right;
    z-index: 99;
    position: fixed;
    right: 0;
    top: 0;
    background: white;
    height: 100%;
    transition: 1s;
    border: 1px solid #d4d7dc;
    border-right: none;
    border-top: none;
    box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
    width: 350px;
    padding: 15px;
    overflow-y: scroll;
}

.upload_doc h1 {
    color: #f97d40;
    font-size: 23px !important;
    text-transform: uppercase;
    margin-bottom: 10px;
    line-height: 35px;
    font-weight: 600 !important;
    letter-spacing: 0px;
}


button.btn.submit.last_month {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fea40d;
    border-radius: 5px !important;
    margin-right: 1%;
    color: #fff;
}

div#notimage_2 {
    display: none;
}

.commision_details p span {
    float: right;
}

.gst-user button {
    width: 100%;
}

span.closed_upload_doc {
    position: absolute;
    right: 5%;
    top: 19px;
    font-size: 18px;
}

.in_p {
    margin-top: 25px;
}

.card_es {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card_S {
    width: 32%;
    /* float: left; */
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 3%;
    margin: 1% 0%;
    background: linear-gradient(85deg, #f66a59, #ffae00);
    height: 128px;
}

.calender {
    width: 79%;
    float: left;
    margin-left: 0%;
}

.clear {
    clear: both;
}

h1.hed {
    color: #f97d40;
    margin-left: 2px !important;
    margin-bottom: 3%;
}

.amt {
    color: #f6f7fb;
    font-size: 12px;
    background: linear-gradient(85deg, #f66a59, #ffae00);
    padding: 2px 8px;
    border-radius: 5px;
    margin-top: 5px;
    display: inline-block;
}

.search.search_earning {
    width: 20% !important;
    float: right;
    margin: 0px;
}

.search.search_earning i {
    background: linear-gradient(85deg, #f66a59, #ffae00);
    padding: 10px !important;
    color: white;
    border-radius: 3px !important;
}

.search.search_earning input {
    border: 1px solid #d6d0d0 !important;
    padding: 2%;
    margin: 0 !important;
    border-radius: 5px !important;
    width: 80%;
    height: 37px;
}

input#myInput {
    border: 2px solid black;
    background: transparent;
    /* border-bottom: 2px solid #ddd; */
}

.search i {
    background: linear-gradient(85deg, #f66a59, #ffae00);
    padding: 10px;
    color: white;
    border-radius: 10px;
}

input.cal1 {
    padding: 5% !important;
}

.renew {
    padding: 1px 10px;
}

table.list-box {
    margin-top: 2%;
}

.wrap1 {
    width: 99%;
    margin-top: 2%;
    margin-bottom: 1%;
    overflow: hidden;
}

.search {
    width: 25%;
    float: right;
    margin-top: 2%;
}

.commision_details p b {
    color: #333;
}

.progress {
    height: 8px;
    width: 70%;
    border-radius: 0.5em;
    overflow: hidden;
    border: thin solid #ddd;
    margin: auto;
    background: linear-gradient(85deg, #f66a59, #ffae00);
    margin-bottom: 8%;
}

.progress .value {
    width: 0%;
    height: 100%;
    background: linear-gradient(85deg, #f66a59, #ffae00);
    transition-property: width;
    transition-duration: 0.5s;
}

.v3dp__datepicker input {
    padding: 6% !important;
}

h1.hed {
    color: #f97d40;

    float: left;
}

.msg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #ffae00;
    transition: 1s;
}

p.txt {
    width: 91%;
    float: left;
    margin: 0;
}

button.btn.submit {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fea40d;
    border-radius: 5px !important;
    margin-right: 1%;
    color: #fff;
}

.v3dp__datepicker {
    width: 17% !important;
}

.error_msg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #db0014;
    transition: 1s;
    color: red;
}

.file-upload {
    position: fixed;
    display: inline-block;
    float: right;
    right: 3%;
    bottom: 3%;
}

label.file-upload__label {
    display: block;
    padding: 9px 9px;
    color: #fff;
    background: #ffae00;
    border-radius: 0.4em;
    transition: background .3s;
    font-size: 15px;
    cursor: pointer;
}

input#upload {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
}

button.back_user {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fd9d16;
    border-radius: 5px !important;
    margin-right: 1%;
    color: #fff;
    padding: 5px 4%;
    margin-left: 37%;
    transition: 1s;
    float: right;
    margin-right: 2%;
    width: 100px;
}
</style>